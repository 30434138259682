<template lang="pug">
  main
    .register(v-if="!signed")
      .register__wrapper.password-reset
        a.register__logo(@click="goRoot")
          include ../../assets/images/logo_title.svg
        .register__form
          button.register__form-close(@click="goRoot")

          .register__form-tabs
            span.password-reset__form-header СМЕНА ПАРОЛЯ
          .register__form-label-desc Введите емайл, с которым регистрировались. Мы пришлем ссылку на смену пароля

          ValidationObserver(ref="form")
            form.register__form-item(
              @submit.prevent="passwordRecovery"
              role="form")
              label.register__form-label
                span.register__form-label-title Ваш емайл
              ValidationProvider(
                :debounce="200"
                name="email"
                rules="requiredEmail|email" )
                div(slot-scope="{ errors, validated, invalid }")
                  input(
                    @input="emailNotFound = false"
                    :class="validated && invalid ? 'input-error' : ''"
                    name="email"
                    v-model="email")
                  div.styled-errors(
                    role="alert"
                    v-if="validated && invalid")
                    p.errors-text {{ errors[0] }}
                  div.styled-errors(
                    role="alert"
                    v-else-if="emailNotFound")
                    p.errors-text Email не найден

              .button-link
                button.register__form-submit.button.button_smaller.button_green(type="submit") Сменить пароль

                a(@click="goSignIn")
                  img(:src="require('images/arrow-left.svg')" alt="" style="position: relative; top: 2px;")
                  | &nbsp;&nbsp; Я передумал
</template>

<script>
import navigationMixin from 'mixins/navigation'
import authMixin from 'mixins/auth'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('requiredEmail', {
  ...required,
  message: 'Введите e-mail'
})
extend('email', {
  ...email,
  message: 'Введите корректный e-mail'
})

export default {
  name: 'PasswordReset',
  data () {
    return {
      email: null,
      emailNotFound: false
    }
  },
  methods: {
    passwordRecovery () {
      this.$refs.form.validate().then(success => {
        if (success) this.passwordRecoveryRequest()
      })
    },
    passwordRecoveryRequest () {
      const params = { email: this.email, redirect_url: 'https://спроектируй.рф/reset_password' }
      this.$api.resetPassword(params)
        .then(() => {
          this.$toasted.show('Ссылка для восстановления пароля была отправлена на указанную электронную почту', { duration: 10000 })
          this.goSignIn()
        })
    }
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [authMixin, navigationMixin]
}
</script>
